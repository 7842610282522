import {createApp} from 'vue';
import App from './App.vue';
import './assets/style.css';

import apolloClient from './apolloClient';

import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import {createVuetify} from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import router from './router';
import VueCryptojs from 'vue-cryptojs';
import {store} from './store.js';

import {fr} from 'vuetify/locale';
import {abilitiesPlugin} from '@casl/vue';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import ability from './ability';

const vuetify = createVuetify({
  components,
  directives,
  locale: {
    locale: 'fr',
    fallback: 'fr',
    messages: {fr},
  },
});

window.addEventListener('storage', () => {
  location.href = '';
});

createApp(App)
  .provide('apollo', apolloClient)
  .use(abilitiesPlugin, ability, {
    useGlobalProperties: true,
  })
  .use(vuetify)
  .use(router)
  .use(VueCryptojs)
  .use(store)
  .use(ToastPlugin)
  .mount('#app');
