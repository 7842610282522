<template>
  <div class="flex duration-300 ease-in-out">
    <button
      class="rounded bg-white w-10 h-10 m-1 hover:bg-gray-100 border print:hidden"
      @click="prevOption"
      v-if="getCurrentIndex() > 0 && showButtons"
    >
      <img :src="chevronRight" alt="" class="ml-2" />
    </button>
    <div
      class="rounded bg-white h-10 w-24 my-1 flex items-center justify-center border"
    >
      <span class="font-semibold">{{ modelValue }}</span>
    </div>
    <button
      class="rounded bg-white w-10 h-10 m-1 hover:bg-gray-100 border print:hidden"
      @click="nextOption"
      v-if="getCurrentIndex() < annees.length - 1 && showButtons"
    >
      <img :src="chevronLeft" alt="" class="ml-2" />
    </button>
  </div>
</template>

<script>
import chevronRight from '../../assets/chevronRight.svg';
import chevronLeft from '../../assets/chevronLeft.svg';
export default {
  data() {
    return {
      chevronLeft,
      chevronRight,
    };
  },
  props: {
    annees: Array,
    modelValue: Number,
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    prevOption() {
      if (this.modelValue == 0) {
        return;
      }
      const currentIndex =
        (this.getCurrentIndex() - 1 + this.annees.length) % this.annees.length;
      this.$emit('update:modelValue', this.annees[currentIndex]);
    },
    nextOption() {
      const currentIndex = (this.getCurrentIndex() + 1) % this.annees.length;
      this.$emit('update:modelValue', this.annees[currentIndex]);
    },
    getCurrentIndex() {
      return this.annees.indexOf(this.modelValue);
    },
  },
};
</script>
