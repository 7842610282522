<template>
  <div class="flex w-full md:pl-4 h-20 shrink-0 shadow pt-1">
    <ul class="flex">
      <li>
        <button>
          <img
            src="../../assets/Frame.svg"
            class="w-6 h-6 my-6 cursor-pointer text-gray-500 flex items-center mr-2 ml-4"
            @click="clickHambuger"
            v-if="!dataOpenSideBar"
          />
        </button>
      </li>
      <li class="mx-4 text-gray-500 flex items-center">
        <router-link to="/accueil" class="flex">
          <img src="../../assets/layout_fluide.svg" class="w-5 h-5 md:mr-2" />
          <span class="hidden md:inline">Accueil</span>
        </router-link>
      </li>
      <li class="mx-4 text-gray-500 flex items-center">
        <router-link
          to="/gestion-membres"
          class="flex"
          v-if="userType == 'dir_etu'"
        >
          <img src="../../assets/circle_user.svg" class="w-5 h-5 md:mr-2" />
          <span class="hidden md:inline">Gestion des comptes</span>
        </router-link>
      </li>
    </ul>
    <div
      class="flex w-flex items-center justify-end py-3 absolute md:right-12 max-sm:right-4 sm:right-4 cursor-pointer"
      @click="toggleDropdown"
    >
      <div class="w-0.5 h-12 mr-6 rounded"></div>
      <div
        class="w-12 h-12 rounded-2xl bg-purple-700 flex justify-center items-center"
      >
        <span class="text-white text-lg font-bold">{{
          nom.charAt(0).toUpperCase()
        }}</span>
      </div>
      <div class="mx-3">
        <div class="hidden md:inline">
          <span class="block text-sm font-bold">
            <span class="text-uppercase">
              {{ nom }}
            </span>

            <span class="text-capitalize">
              {{ ' ' + prenom }}
            </span>
          </span>
          <span class="block text-xs text-gray-500">
            {{ translateType(type) }}
          </span>
        </div>
        <div class="absolute min-w-44 top-full right-6 z-30">
          <DropDown
            :dataOpenDropdown="openDropdown"
            @clickDropdown="value => handleItemClick(value)"
          />
        </div>
      </div>
      <label
        ><img
          src="../../assets/arrow.svg"
          class="w-3 h-2 cursor-pointer"
          alt=""
      /></label>
    </div>
  </div>
</template>
<script>
import {getDecryptedType} from '@/SPA/spa.services';
import DropDown from '../DropDown/DropDown.vue';

export default {
  name: 'TopNavbar',
  props: {
    dataOpenSideBar: Boolean,
    clickHambuger: Function,
    nom: {
      type: String,
      required: true,
    },
    prenom: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      openDropdown: false,
      displayedType: '',
      userType: getDecryptedType(),
    };
  },
  methods: {
    toggleDropdown() {
      this.openDropdown = !this.openDropdown;
    },
    handleItemClick(value) {
      this.$emit('item-clicked', value);
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    translateType(type) {
      const typeClassMap = {
        dir: 'Directeur',
        dir_dept: 'Directeur de département',
        secretaire: 'Secrétaire général',
        dir_etu: 'Directeur des études',
      };
      return typeClassMap[type] ?? '';
    },
  },
  components: {
    DropDown,
  },
};
</script>
