import gql from 'graphql-tag';

export const GET_PFES_ENS = gql`
  query getEnseignantById($id: Int) {
    getEnseignantById(id: $id) {
      pfes {
        id
        titre
        entreprise
        code_projet
      }
    }
  }
`;

export const GET_PFE_YEARS = gql`
  query getAllAnnesPfe {
    getAllAnnesPfe
  }
`;

export const GET_ALL_PFES = gql`
  query getPfes($annee: Int) {
    getPfes(annee: $annee) {
      id
      code_projet
      titre
      entreprise
      cin_encadreur
      coefficient
      annee
      updated_date
    }
  }
`;

export const IMPORT_PFES = gql`
  mutation importerPfes($file: String!) {
    importerPfes(file: $file) {
      success
      message
    }
  }
`;
