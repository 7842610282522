import {defineAbility} from '@casl/ability';
import {isUserLoggedIn} from './router';
import {getDecryptedType} from './SPA/spa.services';

export default defineAbility(can => {
  if (isUserLoggedIn()) {
    const type = getDecryptedType();
    if (type == 'consultant') {
      can('read', '/salles');
      can('read', '/accueil');
    } else {
      can('manage', 'all');
    }
  }
});
